import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8a4de270"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "px-3" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_alert = _resolveComponent("v-alert")!

  return (_openBlock(), _createBlock(_component_v_alert, {
    class: "py-2",
    modelValue: _ctx.show,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.show) = $event)),
    type: _ctx.type,
    icon: "null",
    dismissible: _ctx.dismissible
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.icon !== '')
          ? (_openBlock(), _createBlock(_component_v_icon, {
              key: 0,
              class: "mr-2",
              size: "34"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.icon), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createElementVNode("span", { innerHTML: _ctx.message }, null, 8, _hoisted_2)
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "type", "dismissible"]))
}