
import { Notification } from 'sbc-common-components/src/models/notification'
import NotificationModule from 'sbc-common-components/src/store/modules/notification'
import { computed, defineComponent, onMounted, reactive } from 'vue'
import { useStore } from 'vuex'
import { getModule } from 'vuex-module-decorators'
import 'clickout-event'

export default defineComponent({
  name: 'NotificationPanel',
  props: {
    showNotifications: { default: false }
  },
  setup(props, { emit }) {
    const store = useStore()
    // set modules
    if (!store.hasModule('notification'))
      store.registerModule('notification', NotificationModule)

    //state
    const state = reactive({
      notifications: computed(() => store.state.notification.notifications as Notification[])
    })

    onMounted(async () => {
      getModule(NotificationModule, store)
    })

    const emitClose = (): void => {
        emit('closeNotifications')
    }
    return {
      ...props,  
      ...state,
      emitClose
    }
  }
})
