
import { onMounted, defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'MobileDeviceAlert',
  setup() {
    const mobileDevice = ref(false)
    onMounted(() => {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        mobileDevice.value = true
      }
    })    
    return {
      mobileDevice
    }
  }
})
