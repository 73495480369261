
import { onMounted, defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'BrowserVersionAlert',
  setup() {
    const browserUnSupported = ref(false)
    onMounted(() => {
      if (window.navigator.userAgent.match(/MSIE|Trident/) !== null) {
        browserUnSupported.value = true
      }
    })
    return {
      browserUnSupported,
    }
  }
})
