
import { computed, defineComponent, onMounted, reactive, ref } from 'vue'
import { Product } from 'sbc-common-components/src/models/product'
import ProductModule from 'sbc-common-components/src/store/modules/product'
import { getModule } from 'vuex-module-decorators'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'SbcProductSelector',

  setup() {
    const dialog = ref(false)
    const store = useStore()

    // set modules
    if (!store.hasModule('product')) {
      store.registerModule('product', ProductModule)
    }

    onMounted(async () => {
      getModule(ProductModule, store)
      await syncProducts()
    })

    //state
    const state = reactive({
      products: computed(() => store.state.product.products as Product[]),
      partners: computed(() => store.state.product.partners as Product[]),
    })

    //Actions
    const syncProducts = async () => {
      await store.dispatch('product/syncProducts')
    }
    
    //Methods
    const goToProductPage = (product: Product): void => {
      window.open(product.url, '_blank')
    }

    return {
      ...state,
      dialog,
      goToProductPage,
    }
  }
})
