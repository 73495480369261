import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_overlay = _resolveComponent("v-overlay")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_list_group = _resolveComponent("v-list-group")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!

  return (_ctx.showNotifications)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        onClickout: _cache[1] || (_cache[1] = ($event: any) => (_ctx.emitClose()))
      }, [
        _createVNode(_component_v_overlay),
        _createVNode(_component_v_navigation_drawer, {
          right: "",
          app: "",
          width: 440
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_app_bar, {
              flat: "",
              outlined: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_toolbar_title, { class: "toolbar-title" }, {
                  default: _withCtx(() => [
                    _createTextVNode("What's New at BC Registries")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_btn, {
                  icon: "",
                  large: "",
                  class: "dialog-close",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.emitClose()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => [
                        _createTextVNode("mdi-close")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_list, { flat: "" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_list_group, { color: "primary" }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (item, i) => {
                      return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
                        _createVNode(_component_v_list_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_row, { dense: "" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, {
                                  class: "d-flex",
                                  cols: "1"
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("span", {
                                      class: _normalizeClass(!item.read && (item.priority ? 'dot-red' : 'dot-blue'))
                                    }, null, 2)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_v_col, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_list_item, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_list_item_title, { class: "font-weight-bold list-subtitle" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(item.title), 1)
                                          ]),
                                          _: 2
                                        }, 1024),
                                        _createVNode(_component_v_list_item_subtitle, null, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(item.date), 1)
                                          ]),
                                          _: 2
                                        }, 1024),
                                        _createVNode(_component_v_spacer),
                                        _createVNode(_component_v_list_item, {
                                          innerHTML: item.description
                                        }, null, 8, ["innerHTML"])
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024),
                        (i < _ctx.notifications.length - 1)
                          ? (_openBlock(), _createBlock(_component_v_divider, {
                              key: `${i}-divider`
                            }))
                          : _createCommentVNode("", true)
                      ], 64))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ], 32))
    : _createCommentVNode("", true)
}