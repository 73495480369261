
// External
import { computed, defineComponent, nextTick, onMounted, watch, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { getModule } from 'vuex-module-decorators'
// BC Registry
// sbc modules
import AccountModule from '../modules/account'
import AuthModule from '../modules/auth'
import NotificationModule from 'sbc-common-components/src/store/modules/notification'
// sbc interfaces
import { UserSettings } from 'sbc-common-components/src/models/userSettings'
// sbc services
import LaunchDarklyService from 'sbc-common-components/src/services/launchdarkly.services'
// sbc utils
import {
  ALLOWED_URIS_FOR_PENDING_ORGS, Account, IdpHint, LoginSource, Pages, Role
} from 'sbc-common-components/src/util/constants'
import { AccountStatus, LDFlags } from 'sbc-common-components/src/util/enums'
import ConfigHelper from 'sbc-common-components/src/util/config-helper'
import {
  getAccountIdFromCurrentUrl, removeAccountIdFromUrl, appendAccountId
} from 'sbc-common-components/src/util/common-util'
// Local Components 
import { default as BrowserVersionAlert } from './BrowserVersionAlert.vue'
import { default as MobileDeviceAlert } from './MobileDeviceAlert.vue'
import { default as NotificationPanel } from './NotificationPanel.vue'
import { default as SbcProductSelector } from './SbcProductSelector.vue'
import { useNavigation } from '@/sbc-common-components/composables'

export default defineComponent({
  name: 'SbcHeader',
  components: {
    SbcProductSelector,
    BrowserVersionAlert,
    MobileDeviceAlert,
    NotificationPanel
  },
  props: {
    redirectOnLoginSuccess: { default: '' },
    redirectOnLoginFail: { default: '' },
    redirectOnLogout: { default: '' },
    inAuth: { default: false },
    showActions: { default: true },
    showLoginMenu: { default: false },
    dashboardReturnUrl: { default: '' },
    showProductSelector: { default: false },    
  },

  setup(props, { emit }) {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    
    // set modules
    if (!store.hasModule('account')) store.registerModule('account', AccountModule)
    if (!store.hasModule('auth')) store.registerModule('auth', AuthModule)
    if (!store.hasModule('notification')) store.registerModule('notification', NotificationModule)

    // module actions
    // account
    const loadUserInfo = async () => { await store.dispatch('account/loadUserInfo') }
    const syncAccount = async () => { await store.dispatch('account/syncAccount') }
    const syncCurrentAccount = async (settings: UserSettings) => {
      await store.dispatch('account/syncCurrentAccount', settings)
    }
    const syncUserProfile = async () => { await store.dispatch('account/syncUserProfile') }
    // auth
    const syncWithSessionStorage = () => { store.dispatch('auth/syncWithSessionStorage') }
    // notification
    const markAsRead = async () => { await store.dispatch('notification/markAsRead') }
    const fetchNotificationCount = async () => { await store.dispatch('notification/fetchNotificationCount') }
    const fetchNotificationUnreadPriorityCount = async () => {
      await store.dispatch('notification/fetchNotificationUnreadPriorityCount') }
    const fetchNotificationUnreadCount = async () => {
      await store.dispatch('notification/fetchNotificationUnreadCount') }
    const syncNotifications = async () => { await store.dispatch('notification/syncNotifications') }
    
    // navigation helpers
    const { getContextPath, redirectToPath } = useNavigation()
    
    // constants
    const loginOptions = [
      { idpHint: IdpHint.BCSC, option: 'BC Services Card', icon: 'mdi-account-card-details-outline' },
      { idpHint: IdpHint.BCEID, option: 'BCeID', icon: 'mdi-two-factor-authentication'},
      { idpHint: IdpHint.IDIR, option: 'IDIR', icon: 'mdi-account-group-outline'}
    ]
    const notificationPanel = ref(false)

    // Calculated Value
    const isAuthenticated = computed(() => { return store.getters['auth/isAuthenticated'] as boolean })
    const accountName = computed(() => store.getters['account/accountName'] )
    const switchableAccounts = computed(() => store.getters['account/switchableAccounts'] )
    const username = computed(() => store.getters['account/username'] )
    const currentLoginSource = computed(() => store.getters['auth/currentLoginSource'] as string)
    const currentAccount =computed(() => store.state.account.currentAccount as UserSettings)
    const currentUser = computed(() => store.state.account.currentUser as any)
    const pendingApprovalCount = computed(() => store.state.account.pendingApprovalCount as number)
    const isBceid = computed(() => currentLoginSource.value === LoginSource.BCEID)
    const notificationUnreadCount = computed(() => store.state.notification.notificationUnreadCount as number)
    const notificationCount = computed(() => store.state.notification.notificationCount as number)
    const notificationUnreadPriorityCount = computed(() =>
        store.state.notification.notificationUnreadPriorityCount as number)
    const disableBCEIDMultipleAccount = computed(() =>
        LaunchDarklyService.getFlag(LDFlags.DisableBCEIDMultipleAccount) as boolean || false)      
    const isWhatsNewOpen = computed(() => LaunchDarklyService.getFlag(LDFlags.WhatsNew) as boolean || false)       
    const showTransactions = computed(() => {
      return [Account.PREMIUM, Account.SBC_STAFF, Account.STAFF].includes(currentAccount?.value?.accountType as Account)
    })
    const isStaff = computed(() => currentUser?.value?.roles?.includes(Role.Staff) as boolean || false)       
    const isGovmUser = computed(() => currentUser?.value?.roles?.includes(Role.GOVMAccountUser) as boolean ||
     false)      
    const isBcscOrBceid = computed(
        () => [LoginSource.BCSC.valueOf(), LoginSource.BCEID.valueOf()].indexOf(currentLoginSource.value) >= 0)
    const canCreateAccount = computed(() => {
    const disabledLogins:any = [LoginSource.BCROS.valueOf(), LoginSource.IDIR.valueOf()]
      if (disableBCEIDMultipleAccount.value) {
        disabledLogins.push(LoginSource.BCEID.valueOf())
      }
      return disabledLogins.indexOf(currentLoginSource.value) < 0
    })
  
    // mounted lifecycle
    onMounted(async () => {
      getModule(AccountModule, store)
      getModule(AuthModule, store)
      getModule(NotificationModule, store)
      syncWithSessionStorage()
      if (isAuthenticated?.value) {
        await loadUserInfo()
        await syncAccount()
        await updateProfile()
        // checking for account status
        await checkAccountStatus()
      }

      // fetching what's new information, need to wait the notifications load and get the counts
      await syncNotifications()
      await fetchNotificationCount()
      await fetchNotificationUnreadPriorityCount()
      await fetchNotificationUnreadCount()

      // remove id from URLsince its already stored in session
      if (getAccountIdFromCurrentUrl()) {
        await nextTick()
        window.history.replaceState({}, document.title, removeAccountIdFromUrl(window.location.href))
      }
    })

    // component functions
    const updateProfile = async (): Promise<void> => {
      if (isBceid?.value) {
        await syncUserProfile()
      }
    }
    const goToHome = () => {
      const url = props.inAuth ? Pages.HOME : appendAccountId(Pages.HOME)
      redirectToPath(props.inAuth, url)
    }
    const goToUserProfile = () => {
      const url = props.inAuth ? Pages.USER_PROFILE : appendAccountId(Pages.USER_PROFILE)
      redirectToPath(props.inAuth, url)
    }
    const goToCreateAccount = () => {
      redirectToPath(props.inAuth, Pages.CHOOSE_AUTH_METHOD)
    }
    const goToCreateBCSCAccount = () => {
      const redirectUrl: string = props.dashboardReturnUrl ?
        `${Pages.CREATE_ACCOUNT}?redirectToUrl=${encodeURIComponent(props.dashboardReturnUrl)}` : Pages.CREATE_ACCOUNT
      redirectToPath(props.inAuth, redirectUrl)
    }
    const goToAccountInfo = async (settings: UserSettings) => {
      if (!currentAccount?.value || !settings) {
        return
      }
      await syncCurrentAccount(settings)
      redirectToPath(props.inAuth, `${Pages.ACCOUNT}/${currentAccount?.value?.id}/${Pages.SETTINGS}/account-info`)
    }
    const goToTeamMembers = () => {
      if (!currentAccount?.value) {
        return
      }
      redirectToPath(props.inAuth, `${Pages.ACCOUNT}/${currentAccount?.value.id}/${Pages.SETTINGS}/team-members`)
    }
    const goToTransactions = () => {
      if (!currentAccount?.value) {
        return
      }
      redirectToPath(props.inAuth, `${Pages.ACCOUNT}/${currentAccount?.value.id}/${Pages.SETTINGS}/transactions`)
    }
    const checkAccountStatus = async () => {
      // redirect if accoutn status is suspended
      if ([AccountStatus.NSF_SUSPENDED, AccountStatus.SUSPENDED].some(
          status => status === currentAccount?.value?.accountStatus)
      ) {
        redirectToPath(props.inAuth, `${Pages.ACCOUNT_FREEZ}`)
      } else if (currentAccount?.value?.accountStatus === AccountStatus.PENDING_STAFF_REVIEW) {
        const targetPath = window.location.pathname
        const substringCheck = (element:string) => targetPath.indexOf(element) > -1
        // check if any of the url is the allowed uri
        const isAllowedUrl = ALLOWED_URIS_FOR_PENDING_ORGS.findIndex(substringCheck) > -1
        if (!isAllowedUrl) {
          const accountNme = encodeURIComponent(btoa(accountName?.value))
          redirectToPath(props.inAuth, `${Pages.PENDING_APPROVAL}/${accountNme}/true`)
        }
      }
    }
    const switchAccount = async (settings: UserSettings, inAuth?: boolean) => {
      emit('account-switch-started')
      if (route.params.orgId) {
        // If route includes a URL param for account, we need to refresh with the new account id
        router.push({ name: route.name, params: { orgId: settings.id } })
      }
      await syncCurrentAccount(settings)
      emit('account-switch-completed')

      if (!inAuth) {
        window.location.assign(appendAccountId(`${ConfigHelper.getAuthContextPath()}/${Pages.HOME}`))
      }
    }
    const logout = (): void => {
      if (props.redirectOnLogout) {
        const url = encodeURIComponent(props.redirectOnLogout)
        window.location.assign(`${getContextPath()}signout/${url}`)
      } else {
        window.location.assign(`${getContextPath()}signout`)
      }
    }
    const login = (idpHint: string): void => {
      if (props.redirectOnLoginSuccess) {
        let url = encodeURIComponent(props.redirectOnLoginSuccess)
        url += props.redirectOnLoginFail ? `/${encodeURIComponent(props.redirectOnLoginFail)}` : ''
        window.location.assign(`${getContextPath()}signin/${idpHint}/${url}`)
      } else {
        window.location.assign(`${getContextPath()}signin/${idpHint}`)
      }
    }
    const closeNotificationPanel = async (): Promise<void> => {
      notificationPanel.value = false
      if (notificationUnreadCount.value > 0) {
        await markAsRead()
      }
    }

    // component watchers
    watch(isAuthenticated, async (val) => {
      if (val) {
        await updateProfile()
      }
    })

    return {
      ...props,       
      closeNotificationPanel,
      goToAccountInfo,
      goToCreateAccount,
      goToCreateBCSCAccount,
      goToHome,
      goToTeamMembers,
      goToTransactions,
      goToUserProfile,
      login,
      logout,
      loginOptions,
      store,
      switchAccount,
      syncWithSessionStorage,
      isAuthenticated,
      switchableAccounts,
      accountName,
      username,
      currentAccount,
      currentUser,
      pendingApprovalCount,
      notificationPanel,
      isBceid,
      notificationUnreadCount,
      notificationCount,
      notificationUnreadPriorityCount,
      isWhatsNewOpen,
      showTransactions,
      isStaff,
      isGovmUser,
      isBcscOrBceid,
      canCreateAccount
    }
  }
})
